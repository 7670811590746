import React from 'react'
import { graphql } from 'gatsby'
import { Box, Flex } from 'reflexbox/styled-components'
import SEO from '../components/seo'
import { PageWrap, PageCopy, PageRow, PageHead } from '../components/global'
import PreviewList from '../components/projects/preview-list'

export default ({ data }) => {
  return (
    <PageWrap>
      <SEO
        title="Recent Projects 2016—2020"
        description="Crafting a home alongside people who share a passion for great design is as rewarding as the result. We’ve had the privilege of delivering many exceptional homes."
      />
      <PageRow>
        <PageHead>
          Recent Projects
          <br />
          <span>2016&mdash;2020</span>
        </PageHead>
        <Flex flexDirection="column">
          <Box
            ml={[0, `${(2 / 10) * 100}%`]}
            width={[1, 4 / 8]}
            px={[2, 3]}
            pt={[3, 4]}
            pb={[0, 0]}
          >
            <PageCopy>
              <p>
                Crafting a home alongside people who share a passion for great
                design is as rewarding as the result. We’ve had the privilege of
                delivering many exceptional homes.
              </p>
            </PageCopy>
          </Box>
        </Flex>
      </PageRow>
      <PageRow>
        <PreviewList projects={data.projects} images={data.images} />
      </PageRow>
    </PageWrap>
  )
}

export const query = graphql`
  query {
    projects: allProjectsJson(
      filter: { isComplete: { eq: true } }
      sort: { fields: [completion], order: DESC }
    ) {
      edges {
        node {
          id
          project
          suburb
          feature {
            src
            width
          }
        }
      }
    }
    images: allFile(
      filter: { absolutePath: { regex: "/.images/progress|complete./" } }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
